import * as React from 'react';
import { PopperIcon } from '../Icon/Icon';
import { styles } from './SuccessAlert.styles';

interface Props {
  dataMarker?: string;
  children: React.ReactNode;
  onCloseClick?(): void;
}

const SuccessAlert = (props: Props) => {
  const { children, dataMarker, onCloseClick } = props;

  return (
    <div className='SuccessAlert' data-marker={dataMarker} role='alert'>
      <PopperIcon />
      <span className='SuccessAlert__text'>
        {children}
      </span>
      {
        typeof onCloseClick === 'function' && (
          <button className='SuccessAlert__close icon-close' data-marker='Close alert' onClick={onCloseClick}/>
        )
      }

      <style jsx>{styles}</style>
    </div>
  );
};

export default SuccessAlert;
