import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import saveCart from '../../../../utils/cart/saveCart';
import { restrict } from '../../../../utils/cart/restrict';
import { updateClientCartItems } from '../updateClientCartItems';

const changeClientCartItemAmount = (
  ean: string,
  amount: number,
) => (
  dispatch: Dispatch,
  getState: () => RootState,
): void => {
  const state = getState();
  const clientCartItems = state.cart.cart;
  const newClientCartItems = clientCartItems.map(cartItem => {
    if(cartItem.ean !== ean) {
      return cartItem;
    }
    const unit = cartItem.unit || cartItem.product.unit;
    const quantity = cartItem.product.quantity;
    const limitedAmount = restrict(
      amount,
      unit,
      quantity,
    );

    return {
      ...cartItem,
      amount: limitedAmount,
    };
  });

  saveCart(newClientCartItems);

  dispatch(updateClientCartItems(newClientCartItems));
};

export default changeClientCartItemAmount;
