
export function truncateObject<T extends Record<string, unknown>>(obj: T, n: number): Partial<T> {
  const truncatedObject = {};

  if (n > 0) {
    const keys = Object.keys(obj);
    const truncatedKeys = keys.slice(0, n);
    truncatedKeys.forEach(key => {
      truncatedObject[key] = obj[key];
    });
  }

  return truncatedObject;
}
