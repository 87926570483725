import { Dispatch } from 'redux';
import { openModal } from '.';
import getProductURL from '../../utils/product/getProductURL';
import { RootState } from '../reducers';

interface Params {
  ean: string;
  slug: string;
  backUrl: string;
  linkGetParams?: string;
  /** The property from the event. If true, the product will open in a new window */
  ctrlKeyIsPressed?: boolean;
  /** The property from the event. If true, the product will open in a new window */
  metaKeyIsPressed?: boolean;
}

const openProductModalOrPage =
  (params: Params) => (dispatch: Dispatch, getState: () => RootState) => {
    const {
      ean,
      slug,
      backUrl,
      linkGetParams,
      ctrlKeyIsPressed,
      metaKeyIsPressed,
    } = params;

    const language = getState().app.language;
    const url = getProductURL(
      language,
      ean,
      slug,
      linkGetParams,
    );

    if (ctrlKeyIsPressed || metaKeyIsPressed) {
      window.open(url, '_blank');
      return;
    }

    dispatch(
      openModal('productCardModal', {
        product: {
          ean: ean,
          slug: slug,
        },
        backUrl,
      }),
    );
  };

export default openProductModalOrPage;
