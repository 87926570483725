import { RootState } from '../../../reducers';
import requestPostCartItems from '../requestPostCartItems';
import resetServerCartItemsUpdateMap from '../resetServerCartItemsUpdateMap';
import { ServerCartItemToSend } from '../types';

const sendCartItemsUpdateData = () => (dispatch, getState) => {
  const state: RootState = getState();
  const items: ServerCartItemToSend[] = Object.values(state.serverCart.cartItemsUpdateMap);

  dispatch(requestPostCartItems(items));
  dispatch(resetServerCartItemsUpdateMap());
};

export default sendCartItemsUpdateData;