import cn from 'classnames';
import { Fragment } from 'react';
import { StairsDiscountStep } from 'src/redux/cart/serverCart/types';
import useLocalization from 'src/services/localization/useLocalization';
import { personalizeElement } from 'src/utils/cart/personalizeString';
import { styles } from './FixedDeliveryDiscountHint.styles';
import useFixedDeliveryDiscountHint from './useFixedDeliveryDiscountHint';

export interface FixedDeliveryDiscountHintProps {
  isOwnBrand: boolean;
  steps: StairsDiscountStep[];
  subtotalToNextStep: number;
  variant?: 'cart' | 'notification';
}

export default function FixedDeliveryDiscountHint(props: FixedDeliveryDiscountHintProps) {
  const {
    paidDeliveryText,
    freeDeliveryText,
    toDoPriceText,
    nextPriceText,
    isFinalStep,
    deliveryIsFree,
    nextStepIsFree,
    variant,
  } = useFixedDeliveryDiscountHint(props);
  const { isOwnBrand } = props;

  const localize = useLocalization();

  const boldToDoPrice = <b>{toDoPriceText}</b>;
  const nextStepPriceLabel = nextStepIsFree
    ? <b>{localize('free_delivery_reason')}</b>
    : <Fragment>{localize('delivery_reason')} <b>{nextPriceText}</b></Fragment>;

  return (
    <div
      className={cn('FixedDeliveryDiscountHint', {
        FixedDeliveryDiscountHint_bigText: variant === 'notification',
      })}
      role='tooltip'
    >
      {isFinalStep && !deliveryIsFree && (
        <h2 data-marker='Final_delivery_fee'>🥳 {paidDeliveryText}</h2>
      )}

      {isFinalStep && deliveryIsFree && (
        <h2 data-marker='Final_delivery_fee'>🥳 {freeDeliveryText}</h2>
      )}


      {!isFinalStep && !isOwnBrand && (
        <Fragment>
          <p data-marker='Product_cost_left'>
            {localize('add_products_for')} {boldToDoPrice}
          </p>


          <p data-marker='Get_delivery_fee'>
            {localize('and_get')} {nextStepPriceLabel}
          </p>
        </Fragment>
      )}
      {
        !isFinalStep && isOwnBrand && (
          <p
            data-marker='own products add and get'
            className={`FixedDeliveryDiscountHint__ownBrand_${variant}`}
          >
            {personalizeElement(
              localize('add_own_product_for'),
              [
                boldToDoPrice,
                nextStepPriceLabel,
              ])}
          </p>
        )
      }
      <style jsx>{styles}</style>
    </div>
  );
}
