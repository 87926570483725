import { Dispatch } from 'redux';
import saveCart from '../../../../utils/cart/saveCart';
import { RootState } from '../../../reducers';
import { CartItem } from '../../general/cartTypes';
import { updateClientCartItems } from '../updateClientCartItems';

const removeClientCartItem = (ean?: string | null) => (
  dispatch: Dispatch,
  getState: () => RootState,
): void => {
  const state = getState();
  const cartItems = state.cart.cart;
  let newCartItems: CartItem[];

  if (!ean) {
    newCartItems = [];
  } else {
    newCartItems = cartItems.filter((i) => i.ean !== ean);
  }

  saveCart(newCartItems);

  dispatch(updateClientCartItems(newCartItems));
};

export default removeClientCartItem;
