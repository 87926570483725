import { GAME_NOTIFY } from 'src/redux/constants';
import { useSessionStorage } from 'usehooks-ts';
import getPoints from '../FixedDeliveryDiscountProgressBar/helpers/getPoints';
import useCartData from './useCartData';

export default function useNotificationOfGamification() {
  const { cartPrice, stairsDiscount, cartHasItems } = useCartData();

  const [isInSession, setIsInSession] = useSessionStorage(GAME_NOTIFY, true);

  const deliveryFee = cartPrice.price?.delivery_fee || 0;
  const points = stairsDiscount?.steps && getPoints(stairsDiscount.steps, deliveryFee);
  const withFixedDeliveryDiscount = stairsDiscount?.applicable_for === 'fixed_delivery_fee';
  const gamificationIsActive = stairsDiscount?.current_subtotal > 0;
  const isOpened = withFixedDeliveryDiscount && isInSession && cartHasItems && gamificationIsActive;

  const handleClose = () => {
    setIsInSession(false);
  };

  return {
    points,
    isOpened,
    handleClose,
    steps: stairsDiscount?.steps,
    isOwnBrand: stairsDiscount?.retailer_own_brand,
    currentSubtotal: stairsDiscount?.current_subtotal,
    subtotalToNextStep: stairsDiscount?.subtotal_to_next_step,
  };
}
