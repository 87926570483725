export default function getPointLabel(
  deliveryFee: number,
  stepAmount?: number,
): string {
  if (stepAmount === 0) {
    return '0';
  }

  if (!stepAmount) {
    return deliveryFee / 100 + '';
  }

  return stepAmount / 100 + '';
}
