export function filterObject<T>(obj: T): Partial<T> {
  const newObject: Partial<T> = {};

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key)
      && !!obj[key] !== undefined
      && obj[key] !== null
    ) {
      newObject[key] = obj[key];
    }
  }

  return newObject;
}
