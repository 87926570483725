import { useAppSelector } from 'src/redux/hooks';

export type PhoneNumber = string

export interface UserPhone {
  phone: PhoneNumber;
  is_editable: boolean;
}

export interface UserEmails {
  email: string;
  is_editable: boolean;
}

export interface UserLogin {
  phone: string;
  email: string;
}

export interface SubscriptionsSettings {
  marketing?: boolean;
  products_discounts?: boolean;
  products_availability?: boolean;
  recommendations?: boolean;
}

export interface UserProfileResponse {
  login: UserLogin;
  phones: UserPhone[];
  emails: UserEmails[];
  name: string;
  birthdate: string;
  last_visit: string;
  meta_user_id: string;
  subscribed_to_marketing: boolean;
  has_delivery_presets: boolean;
  has_referral_coupon: boolean;
  is_horeca: boolean;
  need_update_policy: boolean;
  delivered_orders_count: number;
  subscriptions: SubscriptionsSettings;
}

export interface UserProfileData {
  loginPhone: string;
  loginEmail: string;
  firstName: string;
  lastName: string;
  subscribeToMarketing: boolean;
  email: string;
  emailIsEditable: boolean;
  birthDate: string;
  phoneList: UserPhone[];
  isHoreca: boolean;
  deliveredOrdersCount?: number;
}

interface UseUserProfileFunc {
  userProfileData: UserProfileData | undefined;
  isFetching: boolean;
}

const useUserProfile = (): UseUserProfileFunc => {
  const userData = useAppSelector((s) => s.userProfile.userData);
  const isFetching = useAppSelector((s) => s.userProfile.isFetching);

  let userProfileData: UserProfileData;

  if (Object.keys(userData).length) {
    userProfileData = {
      loginPhone: userData.USER_LOGIN_PHONE,
      loginEmail: userData.USER_LOGIN_EMAIL,
      firstName: userData.FIRST_NAME,
      lastName: userData.LAST_NAME,
      subscribeToMarketing: userData.SUBSCRIBE_TO_MARKETING,
      email: userData.USER_EMAIL,
      emailIsEditable: userData.USER_CAN_EDIT_EMAIL,
      birthDate: userData.USER_BIRTH_DATE,
      phoneList: userData.USER_PHONE_LIST,
      isHoreca: userData.IS_HORECA,
      deliveredOrdersCount: userData.deliveredOrdersCount,
    };
  }

  return {
    userProfileData,
    isFetching,
  };
};

export default useUserProfile;
