import { desktop } from 'src/styles/media';
import css from 'styled-jsx/css';

export const styles = css`
  .NotificationOfGamification {
    width: 300px;
    box-shadow: 0px 8px 12px 0px #00000024;
    background-color: #fff;
    padding: 16px;
    pointer-events: all;
    cursor: default;
  }

  .NotificationOfGamification__close {
    margin: 0;
    outline: none;
    border: none;
    background: none;

    pointer-events: all;
    color: var(--colors-brandLightGrey8);
    font-size: 11px;
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .NotificationOfGamification__progressBar {
    margin: 20px 0 7px;
  }

  .NotificationOfGamification__hint {
    margin-right: 20px;
  }

  @media ${desktop} {
    .NotificationOfGamification {
      width: 287px;
    }

    .NotificationOfGamification__close {
      cursor: pointer;
      padding: 0;
      top: 15px;
      right: 15px;
    }
  }
`;
