import { deliveryAvailabilityEnum } from '../../fixtures';
import { DeliveryType } from '../../redux/apiTypes';

const { NOVA_POSHTA, NOVA_POSHTA_FRESH, NOVA_POSHTA_ADDRESS } = deliveryAvailabilityEnum;
const deliveryServices: DeliveryType[] = [NOVA_POSHTA, NOVA_POSHTA_ADDRESS];

export interface GetPurchaseAvailabilityParams {
  availableDeliveryServices?: string[];
  selectedDeliveryType?: DeliveryType;
  excisable: boolean;
}

export function getPurchaseAvailability(
  productParams: GetPurchaseAvailabilityParams,
): boolean {
  const { availableDeliveryServices, selectedDeliveryType, excisable } = productParams;

  if (!selectedDeliveryType) {
    return true;
  }

  if (selectedDeliveryType === NOVA_POSHTA_FRESH && excisable) {
    return false;
  }

  if (
    !deliveryServices.includes(selectedDeliveryType) ||
    !availableDeliveryServices
  ) return true;

  return availableDeliveryServices.includes(selectedDeliveryType);
}
