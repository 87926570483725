import { useEffect, useRef, useState } from 'react';
import { StairsDiscountStep } from 'src/redux/cart/serverCart/types';
import getPoints from './helpers/getPoints';

export interface Params {
  steps: StairsDiscountStep[];
  deliveryFee: number;
}

export default function useFixedDeliveryDiscountProgressBar(params: Params) {
  const { steps, deliveryFee } = params;

  const timeoutRef = useRef(null);
  const isFinalStep = steps.findIndex((s) => s.is_current) === steps.length - 1;
  const [withProgressBar, setWithProgressBar] = useState(!isFinalStep);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const hideProgressBar = () => {
    setWithProgressBar(false);
  };

  useEffect(() => {
    if (isFinalStep) {
      timeoutRef.current = setTimeout(hideProgressBar, 4000);
    } else {
      clearTimeout(timeoutRef.current);
      setWithProgressBar(true);
    }
  }, [isFinalStep]);

  const points = getPoints(steps, deliveryFee);

  return {
    withProgressBar,
    points,
  };
}
