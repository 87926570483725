import { ProductUnit } from 'src/redux/apiTypes';
import { LocalizeFunc } from "src/services/localization/types";

function getAmountSuffix(
  productMeasure: ProductUnit,
  productBundle: number,
  localize: LocalizeFunc,
  BUNDLES_AVAILABLE: boolean,
): string {
  if (BUNDLES_AVAILABLE && productBundle && productBundle > 1) {
    return localize(`general.measure.bundle`);
  }

  return localize(`general.measure.${productMeasure}`);
}

export default getAmountSuffix;
