import { Fragment } from "react";

const personalizeString = (str: string, replacementArray: Array<string | number>): string => {
  return str.replace(/({\d})/g, function (j) {
    return replacementArray[j.replace(/{/, '').replace(/}/, '')];
  });
};

export const personalizeElement = (str: string, replacementArray: Array<JSX.Element>): JSX.Element => {
  const parts = str.split(/{\d+}/g);

  return (
    <span>
      {parts.length > 1 ? (parts.map((item, index) => {
        return <Fragment key={index}>{item}{replacementArray[index]}</Fragment>;
      })) : parts[0]}
    </span>
  );
};

export default personalizeString;
