import { StairsDiscountStep } from 'src/redux/cart/serverCart/types';
import { ProgressBarPoint } from '../../ProgressBarWithCar';
import getPointLabel from './getPointLabel';

export default function getPoints(
  steps: StairsDiscountStep[],
  deliveryFee: number,
): ProgressBarPoint[] {
  const firstPoint: ProgressBarPoint = {
    label: getPointLabel(deliveryFee),
    value: 0,
    dataMarker: 'Start delivery fee',
  };

  const restPoints = steps.map<ProgressBarPoint>((step, i) => {
    return {
      label: getPointLabel(deliveryFee, step.amount),
      value: step.min,
      dataMarker: `${i + 1}_step_discount`,
    };
  });

  const points = [firstPoint, ...restPoints];

  return points;
}
