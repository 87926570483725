import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .SuccessAlert {
      background-color: var(--colors-barleyWhite);
      padding: 12px 40px 12px 16px;
      margin: 0;
      min-height: 48px;
      display: flex;
      align-items: center;
      text-align: left;
      position: relative;
      border-radius: 8px;
    }

    .SuccessAlert__text {
      padding-left: 8px;
      ${typography.h6}
    }

    .SuccessAlert__close {
      position: absolute;
      right: 16px;
      top: 16px;
      background: transparent;
      border: 0;
      outline: none;
      padding: 0;
    }
  `;
