import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { FixedDeliveryDiscountHintProps } from './FixedDeliveryDiscountHint';

export default function useFixedDeliveryDiscountHint(params: FixedDeliveryDiscountHintProps) {
  const { steps, subtotalToNextStep, variant = 'cart' } = params;

  const { currency } = useAppConfig();
  const localize = useLocalization();

  const currentStepIndex = steps.findIndex((s) => s.is_current);
  const currentStep = steps[currentStepIndex];
  const isFinalStep = steps.length - 1 === currentStepIndex;
  const currentStepAmount = currentStep && currentStep.amount / 100;
  const nextStep = steps[currentStepIndex + 1];
  const nextStepAmount = nextStep && nextStep.amount / 100;
  const deliveryIsFree = currentStepAmount === 0;
  const nextStepIsFree = nextStep?.amount === 0;
  const toDoAmount = nextStep && Math.ceil(subtotalToNextStep / 100);

  const currencyText = localize(`general.currency.${currency}`);

  const paidDeliveryText_cart = [
    localize('delivery_cost'),
    currentStepAmount,
    currencyText,
  ].join(' ');

  const toDoPriceText = [
    toDoAmount,
    currencyText,
  ].join(' ');

  const nextPriceText = [
    nextStepAmount,
    currencyText,
  ].join(' ');

  const paidDeliveryText_notification = [
    localize('yuhoo'),
    localize('you_have_got'),
    localize('delivery_reason'),
    currentStepAmount,
    currencyText,
  ].join(' ');

  const freeDeliveryText_cart = localize('free_delivery');

  const freeDeliveryText_notification = [
    localize('yuhoo'),
    localize('you_have_got'),
    localize('free_delivery_reason'),
  ].join(' ');

  const paidDeliveryText = variant === 'cart' ? paidDeliveryText_cart : paidDeliveryText_notification;
  const freeDeliveryText = variant === 'cart' ? freeDeliveryText_cart : freeDeliveryText_notification;

  return {
    paidDeliveryText,
    freeDeliveryText,
    toDoPriceText,
    nextPriceText,
    isFinalStep,
    deliveryIsFree,
    nextStepIsFree,
    variant,
  };
}
