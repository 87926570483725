import { selectCartItems, selectCartPrice, selectStairsDiscount } from 'src/redux/cart/general/cartSelectors';
import { useAppSelector } from 'src/redux/hooks';

export default function useCartData() {
  const stairsDiscount = useAppSelector(selectStairsDiscount);
  const cartPrice = useAppSelector(selectCartPrice);
  const cartHasItems = !!useAppSelector(selectCartItems).length;

  return {
    stairsDiscount,
    cartHasItems,
    cartPrice,
  };
}
