import { Dispatch } from 'redux';
import { diff } from '../../../../utils/js-helpers/numsOperations';
import { sendAddToCartEvent, sendAddingProductsToCart, sendRemoveFromCartEvent, sendRemovingProductsFromCart, sendStatusCart } from '../../../../utils/marketing/enhancedEcommerce';
import { Product } from '../../../apiTypes';
import { RootState } from '../../../reducers';
import changeClientCartItemAmount from '../../clientCart/operations/changeClientCartItemAmount';
import changeServerCartItemAmount from '../../serverCart/operations/changeServerCartItemAmount';
import { selectCartItems } from '../cartSelectors';
import { CartItem } from '../cartTypes';

const changeCartItemAmount = (
  product: Product,
  amount: number,
  place: string,
) => (dispatch: Dispatch, getState: () => RootState): void => {
  const state = getState();
  const cartType = state.app.cartType;
  const currency = state.initialAppConfig.currency;

  if(cartType === null) {
    return;
  }

  let updatedCartItems: CartItem[];

  if(cartType === 'client') {
    dispatch(changeClientCartItemAmount(product.ean, amount));
    updatedCartItems = getState().cart.cart;
  } else {
    dispatch(changeServerCartItemAmount(product.ean, amount));
    updatedCartItems = getState().serverCart.data.items;
  }

  const prevCartItems = selectCartItems(state);
  const prevCartItem = prevCartItems.find(i => i.ean === product.ean);
  const prevAmount = prevCartItem? prevCartItem.amount: 0;

  if(amount > prevAmount) {
    const sendingAmount = diff(amount, prevAmount);
    const productWithAmount = { ...product, amount: sendingAmount };

    sendAddingProductsToCart([productWithAmount], { place, currency });
    sendAddToCartEvent({ products: [productWithAmount], currency, place });
  }

  if(amount < prevAmount) {
    const sendingAmount = diff(prevAmount, amount);
    const productWithAmount = { ...product, amount: sendingAmount };

    sendRemovingProductsFromCart([productWithAmount], { place, currency });
    sendRemoveFromCartEvent({ products: [productWithAmount], currency, place });
  }

  sendStatusCart(updatedCartItems);
};

export default changeCartItemAmount;
