import { LocalizeFunc } from "src/services/localization/types";

function getBundleInfo(bundle: number | null, localize: LocalizeFunc) {
  if (bundle && bundle > 1) {
    return ` \u2022 ${bundle} ${localize('bundles_in_pack')}`;
  }

  return '';
}

export default getBundleInfo;
