import { getPurchaseAvailability } from '../../../../utils/product/getPurchaseAvailability';
import { DeliveryType, Product } from '../../../apiTypes';

export function getAvailability(productInfo: Product, deliveryType?: DeliveryType) {
  const availableDeliveryServices =
    productInfo.restrictions &&
    productInfo.restrictions.available_for_delivery_services;

  const { excisable } = productInfo;

  const isProductAvailable = getPurchaseAvailability({
    availableDeliveryServices,
    selectedDeliveryType: deliveryType,
    excisable,
  });

  return isProductAvailable && productInfo.in_stock;
}
