import { ServerCartItemToSend } from '../types';
import { Dispatch } from 'redux';
import sendCartItemsUpdateData from './sendCartItemsUpdateData';
import updateServerCartItemsUpdateMap from '../updateServerCartItemsUpdateMap';
import debounce from 'lodash/debounce';

const sendCartItemsUpdateDataDebounced = debounce(
  (dispatch) => dispatch(sendCartItemsUpdateData()),
  400,
);

const updateCartItemsOnServerWithDebounce = (
  updateData: ServerCartItemToSend | ServerCartItemToSend[],
) => (dispatch: Dispatch) => {
  updateData = Array.isArray(updateData) ? updateData : [updateData];

  dispatch(updateServerCartItemsUpdateMap(updateData));
  sendCartItemsUpdateDataDebounced(dispatch);
};

export default updateCartItemsOnServerWithDebounce;
