import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .Price__value_title,
    .Price__currency_title {
      ${typography.h3}
    }
    .Price__value_title {
      font-size: 20px;
    }
    .Price__currency_title {
      font-size: 14px;
    }

    .Price__value_subhead,
    .Price__currency_subhead {
      ${typography.h6}
    }

    .Price__value_caption,
    .Price__currency_caption {
      ${typography.h4}
    }

    .Price__value_smallCaption,
    .Price__currency_smallCaption {
      ${typography.text}
      line-height: 14px;
      font-size: 12px;
    }

    .Price__value_smallAccentedCaption,
    .Price__currency_smallAccentedCaption {
      ${typography.smallAccentedText}
    }

    .Price__value_mobileSmall,
    .Price__currency_mobileSmall {
      ${typography.text}
      font-size: 10px;
      line-height: 1;
    }

    .Price__value_mobile{
      ${typography.text}
      font-weight: 600;
      line-height: 20px;
    }

    .Price__currency_mobile {
      ${typography.h9}
      font-weight: 600;
    }

    .Price__value_unavailable,
    .Price__currency_unavailable {
      color: var(--colors-darkenGrey1);
    }

    .Price__value_discount,
    .Price__currency_discount {
      line-height: 17px;
      color: var(--colors-red);
    }

    .Price__value_minor,
    .Price__currency_minor {
      color: var(--colors-brandLightGrey8);
      text-decoration: line-through;
    }
  `;
