import css from 'styled-jsx/css';

export const styles = css`
  .Snackbar {
    width: calc(100% - 32px); /* 32px are side margins  */
    position: absolute;
    left: 16px;
    top: -13px;
    transform: translateY(0);
    opacity: 0;
    transition: all 0.5s;
    visibility: hidden;
  }

  .Snackbar_open {
    transform: translateY(-100%);
    visibility: visible;
    opacity: 1;
  }
`;
