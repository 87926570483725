import { Dispatch } from 'redux';
import { sendRemoveFromCartEvent, sendRemovingProductsFromCart, sendRemovingSingleProductFromCart, sendStatusCart } from '../../../../utils/marketing/enhancedEcommerce';
import { Product } from '../../../apiTypes';
import { RootState } from '../../../reducers';
import removeClientCartItem from '../../clientCart/operations/removeClientCartItem';
import removeServerCartItem from '../../serverCart/operations/removeServerCartItem';
import { selectCartItems } from '../cartSelectors';
import { CartItem } from '../cartTypes';

const removeCartItem = (product: Product | null, place: string) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const cartType = state.app.cartType;
  const currency = state.initialAppConfig.currency;

  if(state.app.cartType === null) {
    return;
  }

  const cartItems = selectCartItems(state);

  // Ecommerce {
  if (!product) {
    // This case happens when a user removes all items from the cart.
    // For the server cart, we may not have products in the cartItems at the moment.
    // @todo: fix this issue when removing all elements is implemented
    const products = cartItems.map(c => ({ ...c.product, amount: c.amount }));
    sendRemovingProductsFromCart(products, { place, currency });
    sendRemoveFromCartEvent({
      products,
      currency,
      place,
    });
  } else {
    const productCartIndex = cartItems.findIndex(c => c.ean === product.ean);
    const amount = +cartItems[productCartIndex].amount;
    sendRemovingSingleProductFromCart(product, { amount, place, currency });
    sendRemoveFromCartEvent({
      products: [{ ...product, amount }],
      currency,
      place,
    });
  }

  const ean = product?.ean || null;
  let updatedCartItems: CartItem[];

  if(cartType === 'client') {
    dispatch(removeClientCartItem(ean));
    updatedCartItems = getState().cart.cart;
  } else {
    dispatch(removeServerCartItem(ean));
    updatedCartItems = getState().serverCart.data.items;
  }

  sendStatusCart(updatedCartItems);
};

export default removeCartItem;
