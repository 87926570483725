import { StairsDiscountStep } from 'src/redux/cart/serverCart/types';
import ProgressBar from '../ProgressBarWithCar/ProgressBar';
import Snackbar from '../Snackbar/Snackbar';
import { styles } from './FixedDeliveryDiscountProgressBar.styles';
import useFixedDeliveryDiscountProgressBar from './useFixedDeliveryDiscountProgressBar';

export interface Props {
  steps: StairsDiscountStep[];
  deliveryFee: number;
  progressValue: number;
}

export default function FixedDeliveryDiscountProgressBar(props: Props) {
  const { steps, deliveryFee, progressValue } = props;

  const { points, withProgressBar } = useFixedDeliveryDiscountProgressBar({
    deliveryFee,
    steps,
  });

  if (!withProgressBar) {
    return null;
  }

  return (
    <div className='FixedDeliveryDiscountProgressBar'>
      <ProgressBar value={progressValue} points={points} />

      <Snackbar steps={steps} />

      <style jsx>{styles}</style>
    </div>
  );
}
