import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import { getSendingToCartItems } from '../../general/helpers/getSendingToCartItems';
import { ServerCartItemToSend } from '../types';
import updateServerCartItemsInState from '../updateServerCartItemsInState';
import updateCartItemsOnServerWithDebounce from './updateCartItemsOnServerWithDebounce';

const removeServerCartItem = (ean?: string | null) => (
  dispatch: Dispatch,
  getState: () => RootState,
): void => {
  const state = getState();
  const cartItems = state.serverCart.data.items;
  const newCartItems = ean ? cartItems.filter(i => i.ean !== ean): [];

  let cartItemsUpdateData: ServerCartItemToSend[];

  if(ean) {
    cartItemsUpdateData = getSendingToCartItems([{ ean, amount: 0 }], 'set');
  } else {
    cartItemsUpdateData = getSendingToCartItems(cartItems.map(i => ({ean: i.ean, amount: 0})), 'set');
  }

  dispatch(updateServerCartItemsInState(newCartItems));
  dispatch(updateCartItemsOnServerWithDebounce(cartItemsUpdateData));
};

export default removeServerCartItem;
