import { LocalizeFunc } from "src/services/localization/types";

export interface BadgesLocalizationsType {
  hit: string | null;
  novelty: string | null;
  bulk: string | null;
  discount: string | null;
  bundle: string | null;
  ownBrandLogo: string | null;
}

export interface BadgesInfo {
  isHit: boolean;
  isBulk: boolean;
  isNovelty: boolean;
  discountValue: number;
  bundleValue: number;
  ownBrandLogo: string | null;
}

function getBadgesLocalizations(
  badgesInfo: BadgesInfo,
  localize: LocalizeFunc,
  bundlesAvailable: boolean,
): BadgesLocalizationsType {
  const {
    isBulk,
    isHit,
    isNovelty,
    discountValue,
    bundleValue,
    ownBrandLogo,
  } = badgesInfo;

  const hit = isHit ? localize('products.hit') : null;
  const novelty = isNovelty ? localize('products.novelty') : null;
  const discount = discountValue ? `-${discountValue} %` : null;
  const bundle =
    bundlesAvailable && bundleValue > 1
      ? `${bundleValue} ${localize('general.measure.pcs')}`
      : null;
  const bulk = isBulk ? localize('products.bulk') : null;

  return {
    bulk,
    hit,
    novelty,
    discount,
    bundle,
    ownBrandLogo,
  };
}

export default getBadgesLocalizations;
