import WithFadeIn from 'src/components/views/WithFadeIn/WithFadeIn';
import FixedDeliveryDiscountHint from '../FixedDeliveryDiscountHint/FixedDeliveryDiscountHint';
import ProgressBar from '../ProgressBarWithCar/ProgressBar';
import { styles } from './NotificationOfGamification.styles';
import useNotificationOfGamification from './useNotificationOfGamification';

export default function NotificationOfGamification() {
  const {
    steps,
    points,
    isOpened,
    isOwnBrand,
    currentSubtotal,
    handleClose,
    subtotalToNextStep,
  } = useNotificationOfGamification();

  if (!isOpened) {
    return null;
  }

  return (
    <WithFadeIn>
      <div
        className='NotificationOfGamification'
        data-marker='notification_of_gamification'
      >
        <div className='NotificationOfGamification__hint'>
          <FixedDeliveryDiscountHint
            subtotalToNextStep={subtotalToNextStep}
            steps={steps}
            variant='notification'
            isOwnBrand={isOwnBrand}
          />
        </div>
        <div className='NotificationOfGamification__progressBar'>
          <ProgressBar value={currentSubtotal} points={points} />
        </div>
      </div>

      <button
        className='NotificationOfGamification__close icon-close'
        data-marker='Close alert'
        onClick={handleClose}
      />

      <style jsx>{styles}</style>
    </WithFadeIn>
  );
}
