import { weightMeasureEnum } from "../../fixtures";
import { Quantity } from "../../redux/apiTypes";

export function getInitialAmount(unit: string, quantity: Quantity = {}, ean: string) {
  if ([weightMeasureEnum.pcs, weightMeasureEnum.kg].includes(unit) === false) {
    throw new Error(`No unit provided. Ean: ${ean}`);
  }

  return quantity?.min || weightMeasureEnum.properties[unit].STEP;
}
