import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .FixedDeliveryDiscountHint p, h2 {
    margin: 0;
    ${typography.h9}
  }

  .FixedDeliveryDiscountHint_bigText p {
    ${typography.text}
  }

  .FixedDeliveryDiscountHint_bigText h2 {
    ${typography.h6}
  }

  .FixedDeliveryDiscountHint__ownBrand_notification {
    max-width: 232px;
  }

  .FixedDeliveryDiscountHint__ownBrand_cart {
    max-width: 310px;
  }
`;
