import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import SuccessAlert from 'src/components/views/SuccessAlert/SuccessAlert';
import { StairsDiscountStep } from 'src/redux/cart/serverCart/types';
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { styles } from './Snackbar.styles';

interface Props {
  steps: StairsDiscountStep[];
}

export default function Snackbar(props: Props) {
  const { steps } = props;

  const { currency } = useAppConfig();
  const localize = useLocalization();

  const [isOpen, setIsOpen] = useState(false);

  const stepIndex = steps.findIndex((s) => s.is_current);
  const timeoutRef = useRef(null);
  const prevStepIndexRef = useRef(stepIndex);

  const closeAlert = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    if (stepIndex > prevStepIndexRef.current) {
      setIsOpen(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(closeAlert, 4000);
    } else if (stepIndex < prevStepIndexRef.current) {
      setIsOpen(false);
    }

    prevStepIndexRef.current = stepIndex;
  }, [stepIndex]);


  const stepAmount = steps[stepIndex] && steps[stepIndex].amount / 100;
  const deliveryIsFree = stepAmount === 0;

  const paidDeliveryText = [
    localize('yuhoo'),
    localize('you_have_got'),
    localize('delivery_reason'),
    stepAmount,
    localize(`general.currency.${currency}`),
  ].join(' ');

  const freeDeliveryText = [
    localize('yuhoo'),
    localize('you_have_got'),
    localize('free_delivery_reason'),
  ].join(' ');

  return (
    <div
      className={cn('Snackbar', {
        Snackbar_open: isOpen,
      })}
    >
      {isOpen && (
        <SuccessAlert
          onCloseClick={closeAlert}
          dataMarker='Cart gamification snackbar'
        >
          {deliveryIsFree ? freeDeliveryText : paidDeliveryText}
        </SuccessAlert>
      )}

      <style jsx>{styles}</style>
    </div>
  );
}
