import { ProductUnit } from 'src/redux/apiTypes';
import { LocalizeFunc } from "src/services/localization/types";
import { weightMeasureEnum } from '../../fixtures';

const formatVolume = (
  volume: number | null,
  localize: LocalizeFunc,
): string | null => {
  if (volume) {
    return volume < 1000
      ? `${volume} ${localize('general.measure.ml')}`
      : `${volume / 1000} ${localize('general.measure.liter')}`;
  }

  return null;
};

const formatWeight = (
  weight: number | null,
  localize: LocalizeFunc,
): string | null => {
  if (weight) {
    return weight < 1000
      ? `${weight} ${localize('general.measure.gram')}`
      : `${weight / 1000} ${localize('general.measure.kg')}`;
  }

  return null;
};

const getFormattedWeight = (
  unit: ProductUnit,
  weight: number | null,
  volume: number | null,
  localize: LocalizeFunc,
) => {
  if (unit === weightMeasureEnum.kg) {
    return localize('general.measure.per_one_kg');
  }

  if (unit === weightMeasureEnum.pcs) {
    return volume ? formatVolume(volume, localize) : formatWeight(weight, localize);
  }

  return null;
};

export default getFormattedWeight;
