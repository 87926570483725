import cn from 'classnames';
import { ReactNode, useEffect, useState } from 'react';

export interface WithFadeInProps {
  children: ReactNode;
}

export default function WithFadeIn(props: WithFadeInProps) {
  const { children } = props;

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsShown(true), 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={cn('WithFadeIn', {
        WithFadeIn_shown: isShown,
      })}
    >
      {children}

      <style jsx>{`
        .WithFadeIn {
          visibility: hidden;
          transform: translateY(10px);
          transition: 0.2s transform ease-in;
        }

        .WithFadeIn_shown {
          visibility: visible;
          transform: translateY(0);
        }
      `}</style>
    </div>
  );
}
