export function normalizeNumber(number: number): number {
  const normalizedStr = number.toFixed(2);
  const pureNumber = parseFloat(normalizedStr);
  return pureNumber;
}

export function sum(a: number, b: number): number {
  const numA = normalizeNumber(a);
  const numB = normalizeNumber(b);
  return normalizeNumber(numA + numB);
}

export function diff(a: number, b: number): number {
  const numA = normalizeNumber(a);
  const numB = normalizeNumber(b);
  return normalizeNumber(numA - numB);
}
