import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import updateServerCartItemsInState from '../updateServerCartItemsInState';
import { getSendingToCartItems } from '../../general/helpers/getSendingToCartItems';
import updateCartItemsOnServerWithDebounce from './updateCartItemsOnServerWithDebounce';

const changeServerCartItemAmount = (ean: string, amount: number) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const state = getState();
  const cartItems = state.serverCart.data.items;
  const newCartItems = cartItems.map((cartItem) => {
    if (cartItem.ean !== ean) {
      return cartItem;
    }

    return {
      ...cartItem,
      amount,
    };
  });
  const cartItemsUpdateData = getSendingToCartItems([{ ean, amount }], 'set');

  dispatch(updateServerCartItemsInState(newCartItems));
  dispatch(updateCartItemsOnServerWithDebounce(cartItemsUpdateData));
};

export default changeServerCartItemAmount;
